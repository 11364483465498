@import "@design";

.calendar-header {
  display: flex;
  align-items: center;
  margin: 10px 0;
  .cal-title {
    align-self: center;
    margin: 10px 20px 0 0;
  }
  button {
    margin-top: 0;
    margin-bottom: 0;
  }
}
